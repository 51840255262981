export function Logo() {
	return (
		<svg width="47" height="26" viewBox="0 0 47 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.4924 25.448C11.7617 25.448 9.36169 24.9253 7.29236 23.88C5.22303 22.8347 3.61236 21.384 2.46036 19.528C1.30836 17.6507 0.732361 15.4747 0.732361 13C0.732361 10.5253 1.30836 8.36 2.46036 6.504C3.61236 4.62667 5.22303 3.16534 7.29236 2.12C9.36169 1.07467 11.7617 0.552002 14.4924 0.552002C17.223 0.552002 19.623 1.07467 21.6924 2.12C23.7617 3.16534 25.3724 4.62667 26.5244 6.504C27.6764 8.36 28.2524 10.5253 28.2524 13C28.2524 15.4747 27.6764 17.6507 26.5244 19.528C25.3724 21.384 23.7617 22.8347 21.6924 23.88C19.623 24.9253 17.223 25.448 14.4924 25.448ZM14.4924 21.672C16.4337 21.672 18.1084 21.32 19.5164 20.616C20.9244 19.912 22.0124 18.9093 22.7804 17.608C23.5697 16.3067 23.9644 14.7707 23.9644 13C23.9644 11.2293 23.5697 9.69334 22.7804 8.392C22.0124 7.09067 20.9244 6.088 19.5164 5.384C18.1084 4.68 16.4337 4.328 14.4924 4.328C12.5724 4.328 10.8977 4.68 9.46836 5.384C8.06036 6.088 6.96169 7.09067 6.17236 8.392C5.40436 9.69334 5.02036 11.2293 5.02036 13C5.02036 14.7707 5.40436 16.3067 6.17236 17.608C6.96169 18.9093 8.06036 19.912 9.46836 20.616C10.8977 21.32 12.5724 21.672 14.4924 21.672Z"
				fill="white"
			/>
			<path
				d="M23.2041 17.192H27.4921C27.6201 18.152 28.0148 18.9947 28.6761 19.72C29.3588 20.4453 30.2548 21.0107 31.3641 21.416C32.4734 21.8 33.7534 21.992 35.2041 21.992C37.3588 21.992 39.0654 21.6613 40.3241 21C41.5828 20.3173 42.2121 19.4 42.2121 18.248C42.2121 17.3093 41.8494 16.6053 41.1241 16.136C40.3988 15.6667 39.0868 15.3147 37.1881 15.08L32.0041 14.408C29.1028 14.024 26.9908 13.3093 25.6681 12.264C24.3668 11.1973 23.7161 9.69334 23.7161 7.752C23.7161 6.28 24.1534 5.01067 25.0281 3.944C25.9241 2.856 27.1828 2.024 28.8041 1.448C30.4254 0.850669 32.3241 0.552002 34.5001 0.552002C36.6548 0.552002 38.5641 0.872004 40.2281 1.512C41.8921 2.152 43.2148 3.048 44.1961 4.2C45.1988 5.33067 45.7428 6.664 45.8281 8.2H41.5401C41.4334 7.34667 41.0708 6.61067 40.4521 5.992C39.8548 5.37333 39.0441 4.89334 38.0201 4.552C36.9961 4.18934 35.7908 4.008 34.4041 4.008C32.4414 4.008 30.8734 4.328 29.7001 4.968C28.5268 5.608 27.9401 6.48267 27.9401 7.592C27.9401 8.44534 28.2814 9.10667 28.9641 9.576C29.6681 10.024 30.8841 10.3653 32.6121 10.6L37.8601 11.336C40.0148 11.6347 41.7108 12.04 42.9481 12.552C44.2068 13.0427 45.1028 13.7147 45.6361 14.568C46.1694 15.4 46.4361 16.4773 46.4361 17.8C46.4361 19.336 45.9668 20.68 45.0281 21.832C44.0894 22.984 42.7668 23.88 41.0601 24.52C39.3748 25.1387 37.4014 25.448 35.1401 25.448C32.8361 25.448 30.7988 25.1067 29.0281 24.424C27.2788 23.72 25.8921 22.7493 24.8681 21.512C23.8441 20.2747 23.2894 18.8347 23.2041 17.192Z"
				fill="white"
			/>
		</svg>
	)
}
