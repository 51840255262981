import clsx from 'clsx'
import Link from 'next/link'
import type { MenuItemResult } from '../data/MenuFragment'
import { Icon } from './Icon'
import { Logo } from './Logo.svg'
import styles from './Nav.module.sass'
import { useEffect, useState } from 'react'
import { usePageLoadingState } from '../utilities/usePageLoadingState'

export function Nav(props: { menuItems: MenuItemResult['items']; activeUrl?: string }) {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const [loading] = usePageLoadingState()
	useEffect(() => {
		setIsMenuOpen(false)
	}, [loading])

	return (
		<nav className={styles.wrapper}>
			<div className={styles.inner}>
				<a href="/" className={styles.logo}>
					<Logo />
				</a>
				<ul className={clsx(styles.menu, isMenuOpen && styles.visible)}>
					<button
						className={styles.cross}
						onClick={() => {
							setIsMenuOpen(false)
						}}
					>
						<Icon name="cross" />
					</button>
					{props.menuItems.map((item) => {
						const link =
							item.link?.type == 'external' ? String(item.link.externalLink) : String(item.link?.internalLink?.url)
						return (
							<li key={item.id} className={props.activeUrl === link ? styles.active : undefined}>
								<Link href={link}>{item.link?.title}</Link>
							</li>
						)
					})}
				</ul>
				<button
					className={styles.burger}
					onClick={() => {
						setIsMenuOpen(true)
					}}
				>
					<Icon name="burger" />
				</button>
			</div>
		</nav>
	)
}
