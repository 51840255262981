import Image from 'next/image'
import type { ImageResult } from '../data/ImageFragment'
import type { LinkResult } from '../data/LinkFragment'
import { Container } from './Container'
import styles from './Footer.module.sass'
import { Link } from './Link'

export function Footer(props: {
	title?: string
	desc?: string
	link?: LinkResult
	secondaryLink?: LinkResult
	instagramNick?: string
	email?: string
	image?: ImageResult
	additionalNote?: string
}) {
	return (
		<footer className={styles.wrapper}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.content}>
						<h2>{props.title}</h2>
						<article>{props.desc}</article>
						<div className={styles.btns}>
							{props.link && <Link link={props.link} isBtn />}
							{props.secondaryLink && <Link link={props.secondaryLink} isBtn />}
						</div>
					</div>
					<div className={styles.image}>
						<img src="/podpis.png" alt="podpis"></img>
						{props.image && (
							<Image
								src={props.image.url}
								alt={props.image.alt ?? ''}
								width={props.image.width}
								height={props.image.height}
							/>
						)}
					</div>
				</div>
				<div className={styles.bottom}>
					<small>
						<span>Kontaktujte mě:</span>
						<span>
							ig: <a href={`https://www.instagram.com/${props.instagramNick}`}>@{props.instagramNick}</a>
						</span>
						<span>
							e-m: <a href={`mailto:${props.email}`}>{props.email}</a>
						</span>
					</small>
					<div className={styles.additionalNote}>{props.additionalNote}</div>
				</div>
			</Container>
		</footer>
	)
}
